import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { HeadingOne } from '../atoms/h1';
import { useLinkComponent } from '../utils/component';
// import { DEVICE_SIZE, mq } from '../utils/mq';
// import { useThemeSwitcher } from '../utils/theme-switcher';

type Section = {
  id?: string;
  title?: string;
  url?: string;
  imageId?: string;
  children?: Section[];
};

export type SectionNavCloudProps = {
  navLinks?: Section[];
};

const SectionNavCloudContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: var(--spacer-m);
  margin: var(--spacer-s-fluid) 0 var(--spacer-m-fluid);
`;

const SectionCloudCurrent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const SectionCloudCurrentTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 48px;
  font-weight: bold;
  line-height: 1.38;
`;

const SectionCloudChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacer-s);
  align-items: center;
`;

const SectionCloudChild = styled.div`
  border-color: ${(props) => (props.theme.isDark ? props.theme.palette.blue : props.theme.palette.blueMedium)};
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  font-family: ${(props) => props.theme.typography.defaultFont};
  font-size: 16px;
  font-weight: normal;
  padding: var(--spacer-xs) var(--spacer-s);
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => (props.theme.isDark ? props.theme.palette.blue : props.theme.palette.blueMedium)};
    color: ${(props) => (props.theme.isDark ? 'white' : props.theme.colors.background)};
  }
`;

export const SectionNavCloud = ({ navLinks }: React.PropsWithChildren<SectionNavCloudProps>) => {
  const LinkComponent = useLinkComponent();

  if (!navLinks || !navLinks.length) {
    return null;
  }

  return (
    <SectionNavCloudContainer>
      {navLinks?.map((navLink, index) => {
        if (index === navLinks.length - 1) {
          return (
            <SectionCloudCurrent key={index}>
              <SectionCloudCurrentTitle>
                <HeadingOne
                  className={classNames({
                    'mod-extra-margin': navLink?.children,
                    'mod-no-margin': !navLink?.children,
                  })}
                >
                  {navLink.title}
                </HeadingOne>
              </SectionCloudCurrentTitle>
              <SectionCloudChildrenContainer>
                {navLink?.children &&
                  navLink.children?.map((child, key) => {
                    const linkProps = {
                      href: child.url,
                    };

                    return (
                      <LinkComponent {...linkProps} key={key}>
                        <SectionCloudChild>{child.title}</SectionCloudChild>
                      </LinkComponent>
                    );
                  })}
              </SectionCloudChildrenContainer>
            </SectionCloudCurrent>
          );
        }

        return null;
      })}
    </SectionNavCloudContainer>
  );
};
