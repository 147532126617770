import React from 'react';
import styled from 'styled-components';
import { useLinkComponent } from '../utils/component';
import { HeadingTwo } from '../atoms/h2';
import { useTranslation } from '../utils/translation';

type SectionData = {
  title?: string;
  url?: string;
};

export interface SectionSelectionProps {
  title: string;
  sections: SectionData[];
}

const SectionSelectionContainer = styled.div``;
const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-xs);
`;

const SectionLine = styled.div`
  background: ${({ theme }) => (theme.isDark ? theme.palette.darkBlack : '#edf0f4')};
  border-radius: 3px;
  transition: all 0.3s;
  width: 100%;

  a {
    color: ${({ theme }) => (theme.isDark ? '#a4adbd' : '#526C8A')};
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding: 9px 18px;
    text-decoration: none;

    svg {
      fill: ${({ theme }) => (theme.isDark ? '#a4adbd' : '#526C8A')};
      transform: rotate(-90deg);
    }
  }

  &:hover {
    background: ${({ theme }) => (theme.isDark ? theme.palette.lightGrey : theme.palette.lightWhite)};
  }
`;

export const SectionSelectionComponent = ({ title, sections }: SectionSelectionProps) => {
  const t = useTranslation();
  const LinkComponent = useLinkComponent();

  return (
    <SectionSelectionContainer>
      <HeadingTwo className="mod-without-mt">{title || t('design.sectionSelection.defaultTitle')}</HeadingTwo>
      <SectionsContainer>
        {sections?.length &&
          sections.length > 0 &&
          sections.map((section, index) => {
            const linkProps = {
              href: section.url,
              title: section.title,
            };

            return (
              <SectionLine key={index}>
                <LinkComponent {...linkProps}>
                  {section.title}
                  <svg width="11" height="9" viewBox="0 0 9 5" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.28571 0L4.5 3.125L7.71429 0L9 0.625L4.5 5L0 0.625L1.28571 0Z" />
                  </svg>
                </LinkComponent>
              </SectionLine>
            );
          })}
      </SectionsContainer>
    </SectionSelectionContainer>
  );
};

export default SectionSelectionComponent;
